body {
  margin: 0;
  font-family: 'CircularStd-Bold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'CircularStd-Bold', sans-serif;
}

/*global style to remove the outline from all buttons on focus. White border.*/
button:focus {
  outline: none;
}
